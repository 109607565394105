import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ConfirmPassword from "../components/ConfirmPassword";
import LoadingPage from "../components/LoadingPage/LoadingPage";
import { useGetAlbumBasicApi } from "../services/apis/album.api";

const AnoLayout = () => {
  const { t } = useTranslation();
  const { albumId } = useParams();
  const { isLoading, data } = useGetAlbumBasicApi(albumId);
  const logo = document
    .querySelector('meta[name="logo"]')
    ?.getAttribute("content");

  if (isLoading) return <LoadingPage />;

  return (
    <div className="scroller flex flex-col w-full">
      <div className="scroller-item h-screen w-full">
        <img
          src={data.thumbnail_url}
          className="absolute top-0 left-0 w-full h-full object-cover"
          alt="photo-detail"
        />
        <div className="absolute inset-0 bg-black bg-opacity-20"></div>
        <div className="m-auto flex justify-center items-center flex-col h-full text-white z-10 relative gap-4">
          <span className="font-semibold text-3xl md:text-5xl">
            {"Album " + data.name}
          </span>
          <div
            className={`flex items-center bg-transparent rounded-md text-md md:text-lg w-fit px-3 py-[6px] border hover:opacity-80 cursor-pointer`}
            onClick={() => {
              const element = document.getElementById("album-body");
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            {t("albumDetail.seemore")}
          </div>
        </div>
        <div className="text-white absolute z-10 bottom-[15%] -translate-x-1/2 left-1/2 flex flex-col gap-3">
          {!!logo && <img className="h-8 w-8" src={logo} alt="Shotpik" />}
          <span className="font-semibold text-base md:text-2xl">
            {data.customer_name}
          </span>
        </div>
      </div>
      {!!data && (
        <div
          id="album-body"
          className="scroller-item w-full bg-white z-10 scroll-ruler overflow-y-auto sm:px-8 px-4"
        >
          <ConfirmPassword havePass={data.password} />
        </div>
      )}
    </div>
  );
};

export default AnoLayout;
