import { createSlice } from '@reduxjs/toolkit';
import { TUserStore } from '../../types/user.type';

const initialState: TUserStore = {
  userProfile: {
    avatar_url: '',
    created_at: '',
    email: '',
    email_verified_at: '',
    facebook: '',
    name: '',
    phone_number: '',
    specialization: '',
    updated_at: '',
    website: '',
    id: 0,
    has_password: false,
    album_in_month: 0,
    membership_plan: {
      created_at: '',
      description: '',
      id: 0,
      name: '',
    },
    setting: {
      created_at: null,
      updated_at: null,
      fav_logo: null,
      id: 0,
      main_logo: null,
      owner_id: 0,
      sub_domain_name: null,
    },
    subscription: {
      id: 0,
      user_id: 0,
      membership_plan_id: 0,
      discount_id: null,
      price: 0,
      start_date: null,
      end_date: null,
      status: 1,
      price_usd: 0,
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      return {
        ...state,
        userProfile: action.payload,
      };
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
