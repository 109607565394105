import { TMembershipPlanPrice } from "../types/membership.type";

export const createURLSearchParams = (object: any): string => {
  const ignoreValues = ["", "undefined", "null", null, undefined];
  let params = new URLSearchParams(object);
  let keysForDel: string[] = [];
  params.forEach((value, key) => {
    if (ignoreValues.includes(value)) {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach((key) => {
    params.delete(key);
  });
  return params.toString();
};

export const clusterPackage = (list: TMembershipPlanPrice[]) => {
  let a: Record<number, TMembershipPlanPrice[]> = {};
  for (const value of list) {
    if (!a[value?.membership_plan_id]) {
      a[value?.membership_plan_id] = [value];
    } else {
      a[value?.membership_plan_id] = [...a[value?.membership_plan_id], value];
    }
  }
  return a;
};

export const focusValidation = (errorInfo: any, formName: string) => {
  const firstErrorField = errorInfo.errorFields[0];
  if (firstErrorField) {
    const inputNode = document.getElementById(
      `${formName}_${firstErrorField.name[0]}`
    );
    if (inputNode) {
      (inputNode as HTMLElement).focus();
    }
  }
};
