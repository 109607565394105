import { TDownloadOptions } from "../components/Albums/DownloadAlbum";
import { DEFAULT_LANGUAGE } from "../constants/commons";
import { localStorageKeys } from "../constants/localStorageKeys";

export const localStorageEvent = new EventTarget();

export const setAccessTokenToLS = (token: string) => {
  localStorage.setItem(localStorageKeys.ACCESS_TOKEN, token);
};

export const setLanguageToLS = (language: string) => {
  localStorage.setItem(localStorageKeys.LANGUAGE_DISPLAY, language);
};

export const setDisplayViewToLS = (display: string) => {
  localStorage.setItem(localStorageKeys.DISPLAY_VIEW, display);
};

export const setRefreshTokenToLS = (token: string) => {
  localStorage.setItem("refreshToken", token);
};

export const setDownloadOptionsToLS = (values: TDownloadOptions) => {
  localStorage.setItem(
    localStorageKeys.DOWNLOAD_OPTIONS,
    JSON.stringify(values)
  );
};

export const clearLS = () => {
  localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
  localStorage.removeItem("refreshToken");
  const clearLSEvent = new Event("clearLS");
  localStorageEvent.dispatchEvent(clearLSEvent);
};

export const getAccessTokenFromLS = () =>
  localStorage.getItem(localStorageKeys.ACCESS_TOKEN) || "";

export const getLanguageFromLS = () =>
  localStorage.getItem(localStorageKeys.LANGUAGE_DISPLAY) || DEFAULT_LANGUAGE;

export const getDisplayViewFromLS = () => {
  return localStorage.getItem(localStorageKeys.DISPLAY_VIEW) || "0";
};

export const getRefreshTokenFromLS = () =>
  localStorage.getItem("refreshToken") || "";

export const getProfileFromLS = () => {
  const result = localStorage.getItem("profile");
  return result ? JSON.parse(result) : null;
};

export const getDownloadOptionsFromLS = () => {
  const result = localStorage.getItem(localStorageKeys.DOWNLOAD_OPTIONS);
  return result ? JSON.parse(result) : null;
};
