import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Heart, Image, MessageSquare, Star } from "react-feather";
import { FILTER_PHOTO } from "../../constants/commons";
import { useTranslation } from "react-i18next";

type TFilterAlbum = {
  setIsFilter: (value: any) => void;
  dfValues: {
    fileCount: number;
    likeCount: number;
    commentCount: number;
    recommendCount: number;
  };
};

const FilterAlbum = (props: TFilterAlbum) => {
  const { setIsFilter, dfValues } = props;
  const { t } = useTranslation();
  const [checkedList, setCheckedList] = useState([]);
  const optionFilter = [
    { label: t("albumDetail.filter.total"), value: '' },
    { label: t("albumDetail.filter.value2"), value: FILTER_PHOTO[0] },
    { label: t("albumDetail.filter.value3"), value: FILTER_PHOTO[1] },
    { label: t("albumDetail.filter.value4"), value: '' },
  ];
  const onChangeFilter = (value: string) => {
    if (checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
    } else setCheckedList([...checkedList, value]);
  };

  useEffect(() => {
    if (checkedList.length === 0) {
      setIsFilter(null);
    } else {
      setIsFilter(checkedList.reduce((a, v) => ({ ...a, [v]: 1 }), {}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  return (
    <div className="flex sm:space-x-2 text-sm items-center flex-wrap sm:flex-nowrap">
      <Tooltip title={optionFilter[0].label}>
      <div
        onClick={() => {
          setCheckedList([]);
        }}
        className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
          checkedList.length === 0
            ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
            : "hover:bg-gray-200/50"
        }`}
      >
        <Image className="w-4" />
        <div className="font-medium">{dfValues.fileCount}</div>
      </div>
      </Tooltip>
      <Tooltip title={optionFilter[2].label}>
        <div
          onClick={() => {
            onChangeFilter(optionFilter[2].value);
          }}
          className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
            checkedList.includes(optionFilter[2].value)
              ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
              : "hover:bg-gray-200/50"
          }`}
        >
          <Heart className="w-4" />
          <div className="font-medium">{dfValues.likeCount}</div>
        </div>
      </Tooltip>
      <Tooltip title={optionFilter[1].label}>
        <div
          onClick={() => {
            onChangeFilter(optionFilter[1].value);
          }}
          className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
            checkedList.includes(optionFilter[1].value)
              ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
              : "hover:bg-gray-200/50"
          }`}
        >
          <Star className="w-4" />
          <div className="font-medium">{dfValues.recommendCount}</div>
        </div>
      </Tooltip>
      <Tooltip title={optionFilter[3].label}>
        <div className="py-1 px-3 flex gap-1 items-center cursor-default transition-all hover:bg-gray-200/50 rounded-3xl">
          <MessageSquare className="w-4" />
          <div className="text-black font-medium">{dfValues.commentCount}</div>
        </div>
      </Tooltip>
    </div>
  );
};

export default FilterAlbum;
