import { Dropdown, Modal } from "antd";
import { useContext, useState } from "react";
import {
  Bell,
  ChevronLeft,
  CreditCard,
  HelpCircle,
  Home,
  LogOut,
  Plus,
  Settings,
  User,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path } from "../../routes/path";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import { setIsAuthenticated } from "../../stores/reducers/auth.reducer";
import { TUserProfile } from "../../types/user.type";
import { clearLS } from "../../utils/localStorage";
import Languages from "../Languages";
import MessageModal from "../MessageModal";
import CreateEditAlbum from "../Albums/CreateEditAlbum";

const Header = ({ user }: { user?: TUserProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const isCreateAlbum = ability.can("can", PERMISSION.CREATE_ALBUM);
  const [isModalOpen, setIsModalOpen] = useState<"logout" | "createAlbum">();
  const handleLogout = () => {
    clearLS();
    dispatch(setIsAuthenticated(false));
  };

  const items = [
    {
      key: "info",
      label: (
        <div className="flex flex-col">
          <div className="text-black font-medium">{user?.name}</div>
          <div className="text-slate-600">{user?.email}</div>
        </div>
      ),
      icon: (
        <div className="w-8 h-8 rounded-full bg-black">
          {" "}
          <img
            className="w-8 h-8 rounded-full"
            src={user?.avatar_url ?? ""}
            alt=""
          />{" "}
        </div>
      ),
    },
    {
      key: "profile",
      label: <Link to="">{t("headerDashboard.accountInformation")}</Link>,
      icon: <User className="w-4" />,
    },
    {
      key: "setting",
      label: <Link to="">{t("headerDashboard.setting")}</Link>,
      icon: <Settings className="w-4" />,
    },
    {
      key: "package",
      label: <div>{t("headerDashboard.servicePackage")}</div>,
      icon: <CreditCard className="w-4" />,
    },
    {
      key: "logout",
      label: t("headerDashboard.logout"),
      icon: <LogOut className="w-4" />,
    },
  ];

  const onClick = ({ key }: { key: string }) => {
    switch (key) {
      case "logout":
        setIsModalOpen("logout");
        break;

      case "profile":
        navigate(path.profile);
        break;

      case "setting":
        navigate(path.setting);
        break;

      case "info":
        break;

      case "package":
        navigate(path.packageManagement);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="sticky top-0 z-40 w-full border-b border-slate-900/10 bg-[#f6f8fa] transition-all">
        <div className="max-w-[1440px] m-auto h-14 md:h-16 sm:px-8 px-4 bg-transparent flex items-center justify-between">
          <div className="flex space-x-3 items-center">
            <Link to={path.home} className="text-black text-2xl font-semibold">
              Shotpik
            </Link>
            {location.pathname.match(
              /\/albums|\/profile|\/setting|\/package-management/
            ) ? (
              <Link
                to={path.dashboard}
                className="flex gap-1 items-center py-[5px] px-3 text-primary hover:opacity-85 bg-red-100 transition-all rounded-full font-medium"
              >
                <ChevronLeft className="w-4 sm:block hidden" />
                <div className="sm:block hidden">
                  {t("headerDashboard.dashboard")}
                </div>
                <Home className="w-4 block sm:hidden" />
              </Link>
            ) : (
              <button
                onClick={() => {
                  setIsModalOpen("createAlbum");
                }}
                disabled={!isCreateAlbum}
                className={`flex gap-1 items-center py-[5px] px-3 text-primary hover:opacity-85 bg-red-100 transition-all rounded-full font-medium ${
                  !isCreateAlbum ? "cursor-not-allowed opacity-60" : ""
                }`}
              >
                <Plus className="w-4" />
                <span className="sm:block hidden">
                  {t("headerDashboard.btnCreate")}
                </span>
              </button>
            )}
          </div>
          <div className="font-semibold text-slate-700 flex space-x-3 items-center">
            <div className="cursor-pointer px-1 hover:text-secondary transition-all">
              <HelpCircle className="w-5" />
            </div>
            <div className="cursor-pointer px-1 hover:text-secondary transition-all">
              <Bell className="w-5" />
            </div>
            <div className="cursor-pointer px-1 hover:text-secondary transition-all">
              <Languages />
            </div>
            <Dropdown
              menu={{
                items,
                onClick,
              }}
            >
              <div className="cursor-pointer px-1 text-primary hover:opacity-85 transition-all">
                <span className="sm:block hidden">{user?.name}</span>
                <span className="sm:hidden flex items-center justify-center w-9 h-9 bg-red-100 rounded-full">
                  {user?.name?.trimStart()?.charAt(0)}
                </span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="text-primary font-semibold text-3xl">
            {t("formCreateAlbum.title")}
          </div>
        }
        open={isModalOpen === "createAlbum"}
        onCancel={() => setIsModalOpen(undefined)}
        footer={null}
      >
        <CreateEditAlbum
          onClick={() => {
            setIsModalOpen(undefined);
          }}
        />
      </Modal>
      <MessageModal
        isVisible={isModalOpen === "logout"}
        onClose={() => setIsModalOpen(undefined)}
        title={t("headerDashboard.confirmLogout.title")}
        type="confirm"
        btnText={t("headerDashboard.confirmLogout.btn")}
        handleClick={() => {
          handleLogout();
        }}
      />
    </>
  );
};

export default Header;
